// import router from "../router";

import axios, { AxiosError } from 'axios';
import LoginInvalidError from '@/domain/errors/LoginInvalidError';
import UnauthorizedError from '@/domain/errors/UnauthorizedError';
import NotFoundError from '@/domain/errors/NotFoundError';
import UnknownError from '@/domain/errors/UnknownError';
import EmailCodeMismatch from '@/domain/errors/EmailCodeMismatch';
import EmailInUseError from '@/domain/errors/EmailInUseError';
import EmailNotConfirmedError from '@/domain/errors/EmailNotConfirmed';
import EmailAlreadyConfirmed from '@/domain/errors/EmailAlreadyConfirmed';
import EmailConfirmationCooldown from '@/domain/errors/EmailConfirmationCooldown';
import EmailConfirmationExpired from '@/domain/errors/EmailConfirmationExpired';
import TokenExpiredError from '@/domain/errors/TokenExpiredError';
import EmailCodeConfirmationInvalid from '@/domain/errors/EmailCodeConfirmationInvalid';

import router from '@/router';
import { useAuthStore } from '@/store/auth.store';
import MaxAttemptsReach from "@/domain/errors/MaxAttemptsReach";

type ErrorTypes =
  'LOGIN_INVALID_ERROR' |
  'UNAUTHORIZED_ERROR' |
  'UNKNOWN_ERROR' |
  'NOT_FOUND_ERROR' |
  'EMAIL_CODE_MISMATCH' |
  'EMAIL_CODE_CONFIRMATION_INVALID' |
  'EMAIL_IN_USE_ERROR' |
  'EMAIL_NOT_CONFIRMED' |
  'EMAIL_ALREADY_CONFIRMED' |
  'EMAIL_CONFIRMATION_COOLDOWN' |
  'EMAIL_CONFIRMATION_EXPIRED' |
  'TOKEN_EXPIRED_ERROR' |
  'MAX_ATTEMPTS_REACH'
;

const ErrorMap: Record<ErrorTypes, new (message: string, data?: unknown) => Error> = {
  LOGIN_INVALID_ERROR: LoginInvalidError,
  UNAUTHORIZED_ERROR: UnauthorizedError,
  UNKNOWN_ERROR: UnknownError,
  NOT_FOUND_ERROR: NotFoundError,
  EMAIL_NOT_CONFIRMED: EmailNotConfirmedError,
  EMAIL_CODE_MISMATCH: EmailCodeMismatch,
  EMAIL_IN_USE_ERROR: EmailInUseError,
  EMAIL_CODE_CONFIRMATION_INVALID: EmailCodeConfirmationInvalid,
  EMAIL_ALREADY_CONFIRMED: EmailAlreadyConfirmed,
  EMAIL_CONFIRMATION_COOLDOWN: EmailConfirmationCooldown,
  EMAIL_CONFIRMATION_EXPIRED: EmailConfirmationExpired,
  TOKEN_EXPIRED_ERROR: TokenExpiredError,
  MAX_ATTEMPTS_REACH: MaxAttemptsReach,
};

axios.defaults.baseURL = import.meta.env.VITE_API_URL;

axios.interceptors.response.use(
  (res) => res,
  (error: AxiosError) => {
    if (error.response?.data) {
      const data = error.response.data as {type: ErrorTypes, message: string, data: unknown};
      const ErrorClass = ErrorMap[data.type] || UnknownError;

      if (ErrorClass === TokenExpiredError) {
        const authStore = useAuthStore();
        authStore.removeUser();
        authStore.removeToken();
        router.push({ name: 'Login' });
      }
      throw new ErrorClass(data.message, data.type);
    }
    if (error.code === '404') {
      throw new NotFoundError(error.message, error);
    } if (error.code === '401') {
      throw new UnauthorizedError(error.message, error);
    } if (error.code === '403') {
      throw new UnauthorizedError(error.message, error);
    }
    throw new UnknownError(error.message, error);
  },
);

// eslint-disable-next-line import/prefer-default-export
export const DoNetworkAPI = axios.create({
  baseURL: import.meta.env.VITE_APP_DO_NETWORK_URL,
});
