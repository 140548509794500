import axios from 'axios';

export interface GetConfigurationHttpBody {
  isInDataPersistenceMaintenance: boolean,
  isToShowAnnounce: boolean
}
export default {
  async getConfiguration() {

    const res = await axios.get<GetConfigurationHttpBody>('/application/configuration', );
    return {
        isInDataPersistenceMaintenance: res.data.isInDataPersistenceMaintenance,
        isToShowAnnounce: res.data.isToShowAnnounce,
    }
  }
};
