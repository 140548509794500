interface EventTagManagerOptions {
  fcShopId: number;
}

export default function useGoogleAnalytics() {
  function initializeAnalytics() {
    const s: Document = document;
    const o = 'script';
    const g = 'https://www.google-analytics.com/analytics.js';
    const r = 'ga';
    window.GoogleAnalyticsObject = r;
    window[r] = window[r]
      || (() => {
        (window[r].q = window[r].q || []).push(window, s, o, g, r);
      });
    window[r].l = new Date().getTime();
    const a: HTMLScriptElement = s.createElement(o);
    const m: Element = s.getElementsByTagName(o)[0];
    a.async = true;
    a.src = g;
    if (m.parentNode !== null) {
      m.parentNode.insertBefore(a, m);
    }
  }

  function registerTag(eventTag: string, options?: EventTagManagerOptions): void {
    const d: Document = document;
    const s = 'script';
    const l = 'dataLayer';
    window[l] = window[l] || [];
    window[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);
    const dl = l !== 'dataLayer' ? `&l=${l}` : '';
    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${eventTag}${dl}`;
    let startCommentary = 'Start Google Tag Manager';
    let endCommentary = 'End Google Tag Manager';
    if (options?.fcShopId) {
      startCommentary = `${startCommentary} ds2_${options.fcShopId}_gtm`;
      endCommentary = `${endCommentary} ds2_${options.fcShopId}_gtm`;
    }
    const startComment = d.createComment(startCommentary);
    const endComment = d.createComment(endCommentary);
    if (f.parentNode) {
      f.parentNode.insertBefore(endComment, f);
      f.parentNode.insertBefore(j, endComment);
      f.parentNode.insertBefore(startComment, j);
    }
  }

  function registerTrack(command: string, tags: string[], options?: object | string): void {
    if (options) window.ga(command, ...tags, options);
    else window.ga(command, ...tags);
  }

  return {
    registerTag,
    registerTrack,
    initializeAnalytics,
  };
}
