import { defineStore } from 'pinia';

export interface CurrentUser {
  id: number,
  firstName: string,
  lastName: string,
  firstNameKana: string,
  lastNameKana: string,
  age: number | null,
  sex: string | null,
  contactTelFlag: boolean,
  contactFaxFlag: boolean,
  contactPostFlag: boolean,
  contactMailFlag: boolean,
  zipCode: string,
  prefId: number,
  cityId: number,
  address: string,
  tel: string,
  fax: string,
  email: string,
  initialPasswordSetFlag: boolean,
  passwordResetHash: string,
  passwordResetExpired: Date,
  twoStepVerificationFlag: boolean,
  deleteFlag: boolean | null,
  registerDatetime: Date,
  updatedAt: Date,
}
const localUser: CurrentUser | null = JSON.parse(localStorage.getItem('user') || 'null');
export const localToken = localStorage.getItem('token');

if (localUser) {
  localUser.registerDatetime = new Date(localUser.registerDatetime);
  localUser.updatedAt = new Date(localUser.updatedAt);
  localUser.passwordResetExpired = new Date(localUser.passwordResetExpired);
}

export interface UserState {
  currentUser: CurrentUser | null,
  token: string,
}
export const useAuthStore = defineStore('user', {
  state: (): UserState => ({
    currentUser: localUser || null,
    token: localToken || '',
  }),
  actions: {
    setUser(user: CurrentUser) {
      this.currentUser = user;
      localStorage.setItem('user', JSON.stringify(user));
    },
    removeUser() {
      this.currentUser = null;
      localStorage.removeItem('user');
    },
    setToken(token: string) {
      this.token = token;
      localStorage.setItem('token', token);
    },
    removeToken() {
      this.token = '';
      localStorage.removeItem('token');
    },
  },
  getters: {
    isAuthorized(state): boolean {
      return !!state.token;
    },
  },
});
