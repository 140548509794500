<template>
<div :class="`d-flex justify-content-${props.justify} align-content-${props.align} align-items-${props.align} px-0`" :style="computeStyle()"
     ref="containerRef">
  <slot></slot>
</div>
</template>

<script setup lang="ts">

import { CSSProperties, defineExpose, defineProps, ref } from 'vue';

const props = defineProps({
  justify: {
    type: String as () => 'start' | 'center' | 'end' | 'between' | 'around',
    default: 'start',
  },
  align: {
    type: String as () => CSSProperties['alignItems'],
    default: 'start',
  },
  direction: {
    type: String as () => 'row' | 'column',
    default: 'row',
  },
  wrap: {
    type: [Boolean, String] as unknown as () => boolean | 'wrap' | 'nowrap' | 'wrap-reverse',
    default: false,
  },
});

const containerRef = ref<HTMLDivElement>();

defineExpose({
  getRef: () => containerRef.value,
});

function computeStyle() {
  const style: CSSProperties = { flexDirection: props.direction };
  if (typeof props.wrap === 'boolean') style.flexWrap = props.wrap ? 'wrap' : 'nowrap';
  else style.flexWrap = props.wrap;
  return style;
}

</script>

<style scoped>

</style>
