import axios, { AxiosResponse } from 'axios';
import NotFoundError from '@/domain/errors/NotFoundError';

export interface AnalyticsDecodeFcForm {
  encodedId: string;
}

export interface AnalyticsDecodeFcResult {
  decodedId: number;
}

export interface AnalyticsDirectManagementForm {
  encodedId: string;
}

export interface AnalyticsDirectManagementResult {
  isDirectManagement: boolean;
}

export interface AnalyticsResolveFcShopIdForm {
  fcShopId: number;
}

export interface AnalyticsResolveFcShopIdResult {
  gtmTag: string,
  fcShopId: number
}

export default {
  async decodeFcShopId(form: AnalyticsDecodeFcForm): Promise<AnalyticsDecodeFcResult> {
    const res = await axios.post('/analytics/decodeFcShop', { fcs: form.encodedId });
    const data = res.data as string;
    return {
      decodedId: Number.parseInt(data, 10),
    };
  },
  async isDirectManagement(form: AnalyticsDirectManagementForm): Promise<AnalyticsDirectManagementResult> {
    const res = await axios.post('/management', { fcs: form.encodedId });
    const { data } = res;
    return {
      isDirectManagement: Boolean(data),
    };
  },
  async resolveFcShopId(form: AnalyticsResolveFcShopIdForm): Promise<AnalyticsResolveFcShopIdResult> {
    const res = await axios.post('/analytics/resolver', { fcShopId: form.fcShopId });
    const { data } = res;
    if (!data.length) throw new NotFoundError('FcShopId not found');
    return {
      gtmTag: data[0].gtmTag,
      fcShopId: data[0].fcShopId,
    };
  },
};
