<template>
  <div :class="linkClass" :style="style" @click="onClick" @keyup.enter="onClick">
    <slot />
  </div>
</template>

<script setup lang="ts">

import { computed, CSSProperties, defineProps } from 'vue';
import { Colors } from '@/store/theme.store';
import { useTheme } from '@/composable/ui/useTheme';
import { useWindow } from '@/composable/ui/useWindow';
import {useRouter} from "vue-router";

const props = defineProps({
  size: {
    type: String as () => 'xs' | 'sm' | 'md' | 'lg',
    default: 'md',
  },
  bold: Boolean,
  color: { type: String as () => keyof Colors, default: 'black' },
  justify: {
    type: String as () => 'start' | 'center' | 'end',
    default: 'start',
  },
  newWindow: Boolean,
  route: {
    type: String,
    required: true,
  },
  hoverUnderline: {
    type: Boolean,
    default: false,
  },
});
const sizes = {
  xs: 14,
  sm: 16,
  md: 18,
  lg: 60,
};

const textAligns = {
  start: 'left',
  center: 'center',
  end: 'right',
};

const theme = useTheme();
const window = useWindow();
const router = useRouter();

const style = computed((): CSSProperties => ({
  'font-size': `${sizes[props.size]}px`,
  minHeight: `${sizes[props.size] + 7}px`,
  fontWeight: props.bold ? 700 : 400,
  color: theme.resolveColor(props.color),
  textAlign: textAligns[props.justify] as CSSProperties['textAlign'],
}));

const linkClass = computed(() => (props.hoverUnderline ? 'base-link link' : 'base-link'));

function onClick() {
  if (props.newWindow) {
    window.openInNewTab(props.route);
  } else {
    router.push({ name: props.route});
  }
}

</script>

<style scoped>

div {
  font-family: "Noto Sans JP", "源ノ角ゴシック", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.base-link {
  cursor: pointer;
  color: black;
}
.link:hover {
  text-decoration: underline;
}
</style>
