import { createApp } from 'vue';
import RippleJs from '@/plugins/ripple';
import { VueQueryPlugin } from 'vue-query';
import store from './store/index';
import App from './App.vue';
import router from './router/index';

import './services/http/index';
import 'bootstrap/dist/css/bootstrap-grid.css';

createApp(App)
  .directive('ripple', RippleJs)
  .use(router)
  .use(store)
  .use(VueQueryPlugin)
  .mount('#app');
