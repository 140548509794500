import {onMounted, ref} from "vue";
import {useHttp} from "@/composable/service/useHttp";
import applicationHttp from "@/services/http/application.http";
import useAsyncState from "@/composable/service/useAsyncState";


export default function useApplicationConfig() {
    const http = useHttp(applicationHttp.getConfiguration, {singleton: true});
    const state = useAsyncState(http.request, { default: {isInDataPersistenceMaintenance: false, isToShowAnnounce: false}, globalStateKey: 'configuratuinb' });
    onMounted(() => {
        state.request({});
    })

    return {
        configuration: state.data,
    }
}
