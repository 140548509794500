import { defineStore } from 'pinia';
import { CSSProperties } from 'vue';

export type HEX = `#${string}`;

export interface Colors {
  primary: HEX;
  secondary: HEX;
  accent: HEX;
  success: HEX;
  error: HEX;
  warning: HEX;
  gray: HEX;
  lightGray: HEX;
  lighterGray: HEX;
  white: HEX;
  black: HEX;
  red: HEX;
  [key: string]: HEX;
}

export type Color = keyof Colors | CSSProperties['color'] | HEX | string;

export interface ThemeStoreType {
  currentColor: Colors,
}

function registerCssColors(color: Colors) {
  const style = document.createElement('style');
  style.type = 'text/css';
  document.getElementsByTagName('head')[0].appendChild(style);

  Object.keys(color).forEach((key) => {
    document.documentElement.style.setProperty(`--${key}`, color[key as keyof typeof color]);
    style.sheet?.insertRule(`.${key}{background-color: var(--${key})`, 0);
    style.sheet?.insertRule(`.text-${key}{color: var(--${key})`, 0);
  });
}

const defaultThemeStore: ThemeStoreType = {
  currentColor: {
    primary: '#004680',
    secondary: '#A7BCD6',
    accent: '#F3E600',
    success: '#00BA88',
    error: '#ED2E50',
    warning: '#F4B740',
    gray: '#DCE4EF',
    lightGray: '#D2D2D2',
    lighterGray: '#E5E5E5',
    white: '#FFFFFF',
    black: '#000000',
    red: '#E50019',
    yellow: '#F3E600',

    primaryLight: '#7EA1BE',
    primaryDark: '#003765',

    warningLight: '#FFD789',
    warningDark: '#946200',

    errorLight: '#FF5F5F',
    errorDark: '#A31A1A',

    successLight: '#34EAB9',
    successDark: '#00966D',

    grayLighter: '#F6F8FB',
    grayLight: '#EDF2F7',
    grayDark: '#CAD7E6',
    grayDarker: '#B9C9DE',

    title: '#14142B',
    body: '#4E4B66',
    label: '#6E7191',
    placeholder: '#A0A3BD',
    line: '#D9DBE9',
    inputBackground: '#EFF0F7',
    background: '#F7F7FC',
    offWhite: '#FCFCFC',
    tableItemBorder: '#F0F2F5',

    border: '#969696',
    blackText: '#080808',

    textLight: '#202020',
    borderColor: '#888888',
    backgroundGray: '#f6f6f6',
  },
};
registerCssColors(defaultThemeStore.currentColor);
export const useThemeStore = defineStore('theme', {
  state: (): ThemeStoreType => (defaultThemeStore),
  actions: {
    setColors(color: Colors) {
      this.currentColor = color;
      registerCssColors(color);
    },
  },
  getters: {
    colors(state) {
      return state.currentColor;
    },
  },
});

export default useThemeStore;
