<template>
<div :style="computeStyle()"
     ref="containerRef">
  <slot></slot>
</div>
</template>

<script setup lang="ts">

import { CSSProperties, defineExpose, defineProps, PropType, ref } from 'vue';

const props = defineProps({
  columnFraction: {
    type: Array as () => number[],
  },
  rowGap: {
    type: [String, Number] as PropType<string | number>,
  },
  justify: {
    type: String as () => 'start' | 'center' | 'end' | 'between' | 'around',
    default: 'start',
  },
  align: {
    type: String as () => 'start' | 'center' | 'end' | 'between' | 'around',
    default: 'start',
  },
});

const containerRef = ref<HTMLDivElement>();

defineExpose({
  getRef: () => containerRef.value,
});

function computeStyle() {
  const style: CSSProperties = { display: 'grid', justifyContent: props.justify, alignItems: props.align };
  if (props.columnFraction) style.gridTemplateColumns = props.columnFraction.map((fraction) => `${fraction}fr`).join(' ');
  if (props.rowGap) style.rowGap = Number.isInteger(props.rowGap) ? `${props.rowGap}px` : props.rowGap;
  return style;
}

</script>

<style scoped>

</style>
