import { Color, Colors, HEX, useThemeStore } from '@/store/theme.store';

// eslint-disable-next-line import/prefer-default-export
export function useTheme() {
  const theme = useThemeStore();

  function hexToRgb(hex: HEX) {
    const rgb = [hex.substring(1, 3), hex.substring(3, 5), hex.substring(5, 7)];
    return {
      r: parseInt(rgb[0], 16),
      g: parseInt(rgb[1], 16),
      b: parseInt(rgb[2], 16),
    };
  }

  function colorToGray(color: Color) {
    const hexColor = resolveColor(color);
    const rgb = hexToRgb(hexColor);
    return Math.round((rgb.r * 299) + (rgb.g * 587) + (rgb.b * 114)) / 1000;
  }

  function contrastColor(color: Color) {
    if (color === 'transparent') return theme.colors.black;
    const gray = colorToGray(color);
    return gray > 127 ? theme.colors.black : theme.colors.white;
  }

  function resolveColor(color: Color) {
    return theme.colors[color as keyof Colors] || color;
  }

  return {
    colors: 1,
    breakPoints: {},
    resolveColor,
    contrastColor,
  };
}
