<template>
<flex-layout class="w-100" justify="center" align="center">
  <div class="col-12 p-2 pa-md-0 " :class="`col-md-${props.md} col-lg-${props.lg} `">
    <slot/>
  </div>
</flex-layout>
</template>

<script setup lang="ts">

import FlexLayout from '@/components/Layouts/FlexLayout.vue';
import { defineProps } from 'vue';

const props = defineProps({
  md: {
    type: String as () => '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12',
    default: '11',
  },
  lg: {
    type: String as () => '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12',
    default: '8',
  },
});
</script>

<style scoped>

</style>
