import { Ref, ref } from 'vue';

export interface AsyncStateOptions<DefaultValue, Result> {
  default?: DefaultValue;
  globalStateKey?: string;
  dataRef?: Ref<Result>;
}

export default function useAsyncState<Args = void, Result = void, ErrorType extends Error = Error, DefaultValue = null>(
  fn: (args: Args) => Promise<Result>,
  options?: AsyncStateOptions<DefaultValue, Result>,
) {
  const loading = ref(false);
  const error = ref<ErrorType | null>(null) as Ref<ErrorType | null>;
  const data = options?.dataRef
    ? options.dataRef
    : ref<Result | DefaultValue>(options?.default || null as DefaultValue) as Ref<Result | DefaultValue>;
  return {
    loading,
    error,
    data,
    async request(args: Args) {
      loading.value = true;
      error.value = null;
      try {
        data.value = (await fn(args));
        return data.value;
      } catch (e: unknown) {
        error.value = (e as ErrorType);
        throw error.value;
      } finally {
        loading.value = false;
      }
    },
  };
}
