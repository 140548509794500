import { RouteParamsRaw, useRouter } from 'vue-router';

// eslint-disable-next-line import/prefer-default-export
export function useWindow() {
  const router = useRouter();

  function open(routeName: string) {
    const routeData = router.resolve({ name: routeName });
    window.open(routeData.href);
  }
  function openInNewTab(routeName: string, opts: { params?: RouteParamsRaw, query?: Record<string, string> } = {}) {
    if (!routeName.startsWith('http')) {
      const routeData = router.resolve({ name: routeName, ...opts });
      window.open(routeData.href, '_blank');
    } else {
      window.open(routeName, '_blank');
    }
  }
  function scrollTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return {
    open,
    openInNewTab,
    scrollTop,
  };
}
