<template>
  <img v-bind="$attrs" decoding="async" :src="currentSource" :onerror="fallbackError" :alt="props.alt" :style="style" :loading="loading">
</template>

<script lang="ts" setup>
import {computed, defineProps, StyleValue, watch} from 'vue';
import { CSSProperties } from '@vue/runtime-dom';

const props = defineProps({
  src: { type: String, default: '' },
  alt: String,
  height: { type: [String, Number], default: '100%' },
  maxHeight: { type: [String, Number], default: '100%' },
  width: { type: [String, Number], default: '100%' },
  maxWidth: { type: [String, Number], default: '100%' },
  fallbackSrc: { type: String, default: '' },
  fit: { type: String as () => CSSProperties['objectFit'] },
  loading: {
    type: String as () => 'lazy' | 'eager',
    default: 'lazy',
  },
});

const emit = defineEmits(['imageNotFound'])

function resolveSource(src: string) {
  if (src.startsWith('https')) return src;
  return new URL(`/src/assets/img/${src}`, import.meta.url).href;
}

const currentSource = computed(() => resolveSource(props.src));

const style = computed((): StyleValue => ({
  maxWidth: typeof props.maxWidth === 'number' ? `${props.maxWidth}px` : props.maxWidth,
  maxHeight: typeof props.maxHeight === 'number' ? `${props.maxHeight}px` : props.maxHeight,
  height: typeof props.height === 'number' ? `${props.height}px` : props.height,
  width: typeof props.width === 'number' ? `${props.width}px` : props.width,
  objectFit: props.fit ? props.fit : 'initial',
}));

function fallbackError(this: HTMLImageElement) {
  if (props.fallbackSrc) {
    const src = resolveSource(props.fallbackSrc);
    if (this.src !== src) this.src = src;
  } emit('imageNotFound');
}
</script>

<style scoped>

</style>
