<template>
  <div class="base-text" :style="style" :class="[props.noSelection ? 'no-selection': '']">
    <slot></slot>
    <required-tag-text v-if="props.requiredTag" class="ps-1 no-selection" />
  </div>
</template>

<script lang="ts" setup>

import { computed, CSSProperties } from 'vue';
import { Colors } from '@/store/theme.store';
import { useTheme } from '@/composable/ui/useTheme';
import RequiredTagText from '@/components/Texts/RequiredTagText.vue';
import { useBreakpoint } from '@/composable/ui/useScreen';

const theme = useTheme();
const breakpoint = useBreakpoint();
const props = defineProps({
  size: {
    type: String as() => 'xs' |'sm' | 'md' | 'lg',
    default: 'md',
  },
  bold: Boolean,
  color: { type: String as () => keyof Colors, default: 'body' },
  justify: {
    type: String as () => 'start' | 'center' | 'end',
    default: 'start',
  },
  requiredTag: {
    type: Boolean,
    default: false,
  },
  noWrap: Boolean,
  noSelection: Boolean,
});
const sizes = {
  xs: 12,
  sm: 14,
  md: 16,
  lg: 20,
};
const mobileSizes = {
  xs: 12,
  sm: 14,
  md: 16,
  lg: 20,
};

const textAligns = {
  start: 'left',
  center: 'center',
  end: 'right',
};

const style = computed((): CSSProperties => ({
  'font-size': `${breakpoint.smAndDown ? mobileSizes[props.size] : sizes[props.size]}px`,
  minHeight: `${sizes[props.size] + 10}px`,
  fontWeight: props.bold ? 700 : 400,
  color: theme.resolveColor(props.color),
  textAlign: textAligns[props.justify] as CSSProperties['textAlign'],
  whiteSpace: props.noWrap ? 'nowrap' : 'pre-wrap',
}));

</script>

<style scoped>
div {
  font-family: "Noto Sans JP", "源ノ角ゴシック", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  letter-spacing: 1px;
  text-align: left;
  word-break: break-word;
}

.no-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
