<template>
  <span class="px-1" :style="requiredTagStyle">{{text || "必須"}}</span>
</template>

<script lang="ts" setup>

import { computed, CSSProperties, defineProps } from 'vue';
import { useTheme } from '@/composable/ui/useTheme';
import { Colors } from '@/store/theme.store';

const theme = useTheme();
const props = defineProps({
  size: {
    type: String as() => 'xs' |'sm' | 'md' | 'lg',
    default: 'sm',
  },
  bold: Boolean,
  text: String,
  color: { type: String as () => keyof Colors, default: 'body' },
  justify: {
    type: String as () => 'start' | 'center' | 'end',
    default: 'start',
  },
  requiredTag: {
    type: Boolean,
    default: false,
  },
});
const sizes = {
  xs: 14,
  sm: 16,
  md: 18,
  lg: 20,
};

const textAligns = {
  start: 'left',
  center: 'center',
  end: 'right',
};
const requiredTagStyle = computed((): CSSProperties => ({
  'font-size': `${sizes[props.size]}px`,
  minHeight: `${sizes[props.size] + 7}px`,
  fontWeight: 700,
  color: theme.resolveColor('error'),
  textAlign: textAligns[props.justify] as CSSProperties['textAlign'],
  marginTop: 'auto',
  marginBottom: 'auto'
}));
</script>

<style scoped>

</style>
