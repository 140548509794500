import analyticDonetworkHttp from '@/services/http/analytic.http';
import useGoogleAnalytics from '@/composable/service/useGoogleAnalytics';
import { useHttp } from '@/composable/service/useHttp';
import useAsyncState from '@/composable/service/useAsyncState';

interface InitAnalyticsOptions {
  encodedFcShopId?: string,
  tagManagerCode?: string,
  hasCustomTagManager?: boolean
}

const defaultGoogleCode = import.meta.env.VITE_APP_GOOGLE_TAG_MANAGER_DEFAULT_CODE;
const directManagementGoogleCode = import.meta.env.VITE_APP_GOOGLE_TAG_MANAGER_DIRECT_MANAGEMENT_CODE;

export default function useAnalytics() {
  const { registerTag, registerTrack, initializeAnalytics } = useGoogleAnalytics();
  const { request: getIsDirectManagement } = useHttp(analyticDonetworkHttp.isDirectManagement);
  const { request: getFcShopIdTagManager } = useHttp(analyticDonetworkHttp.resolveFcShopId);
  const { request: getDecodedFcShopId } = useHttp(analyticDonetworkHttp.decodeFcShopId);

  async function initAnalytics(form: InitAnalyticsOptions): Promise<void> {
    initializeAnalytics();
    const { encodedFcShopId, tagManagerCode, hasCustomTagManager } = form;

    if(hasCustomTagManager && tagManagerCode) return registerTag(tagManagerCode);
    if (!encodedFcShopId) return registerTag(defaultGoogleCode);

    const { decodedId: fcShopId } = await getDecodedFcShopId({ encodedId: encodedFcShopId });
    const { isDirectManagement } = await getIsDirectManagement({ encodedId: encodedFcShopId });

    if (isDirectManagement) {
      registerTag(directManagementGoogleCode, { fcShopId });
    } else if (fcShopId) {
      const fcShopIdTag = await getFcShopIdTagManager({ fcShopId });
      registerTag(fcShopIdTag.gtmTag, { fcShopId });
    }
    if (tagManagerCode) {
      registerTrack('create', [tagManagerCode, 'auto'], { allowLinker: true });
      registerTrack('require', ['linker']);
      registerTrack('linker:autoLink', [tagManagerCode]);
      registerTrack('send', ['pageview']);
      registerTrack('create', ['UA-6639858-53', 'auto'], { name: 'myTracker' });
      registerTrack('myTracker.send', ['pageview']);
    }
    return undefined;
  }
  const asyncState = useAsyncState(initAnalytics);
  return {
    useAnalytics: asyncState.request,
    loading: asyncState.loading,
    error: asyncState.error,
  };
}
